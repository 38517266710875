<template>
  <v-dialog
    v-model="dialogTask"
    persistent
    scrollable
    style="max-height: 500px"
    max-width="700px"
    class="comments"
  >
    <v-card>
      <v-toolbar color="indigo" dark dense>
        <section><h3 class="headline white--text">Task</h3></section>
        <v-spacer></v-spacer>
        <section>
          <v-btn icon @click="$emit('false', (edttask = false))" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </section>
      </v-toolbar>
      <v-card-text v-if="$apollo.queries.taskById.loading">
        <div class="d-flex justify-center">
          <v-progress-circular
            :size="40"
            :width="7"
            color="indigo"
            indeterminate
            class="mb-2"
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <section class="d-flex w-100 justify-space-between mt-5">
          <div class="d-flex align-center">
            <v-avatar size="70" class="mr-2" v-if="avatar">
              <img :src="avatar" class="_fit" alt="" />
            </v-avatar>
            <v-btn
              :color="
                taskById.tahap == 'NONE'
                  ? 'cyan'
                  : taskById.tahap == 'PROGRESS'
                  ? 'warning'
                  : 'succsess'
              "
              icon
            >
              <v-icon> mdi-check-circle-outline </v-icon>
            </v-btn>
            <v-text-field
              v-if="edttask == true"
              v-model="taskById.judul"
            ></v-text-field>
            <h2 v-else>{{ taskById.judul }}</h2>
          </div>
          <div v-if="taskById.user.id == id">
            <v-card-actions>
              <v-btn
                color="cyan"
                dark
                v-if="edttask == false"
                @click="edttask = true"
                ><v-icon>mdi-pencil</v-icon>Ubah</v-btn
              >
              <v-btn color="red" dark @click="delTask(taskById.id)"
                ><v-icon> mdi-delete </v-icon> hapus task</v-btn
              >
            </v-card-actions>
          </div>
        </section>
        <v-row>
          <v-col cols="12" md="7">
            <v-row>
              <v-col cols="12" md="4"><label>Dibuat Oleh</label></v-col>
              <v-col cols="12" md="7">{{ taskById.user.nama_lengkap }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4"><label>Keterangan</label></v-col>
              <v-col cols="auto" md="7" v-if="edttask == true">
                <vue-editor
                  id="responsibilityEdit"
                  placeholder="Keterangan jadwal"
                  v-model="taskById.keterangan"
                  :editorToolbar="customToolbar"
                  class="my-5"
                ></vue-editor>
              </v-col>
              <v-col cols="12" md="7" v-else
                ><p v-html="taskById.keterangan"></p
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4"><label>Waktu Mulai</label></v-col>
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                v-if="edttask == true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="taskById.start_date"
                    label="Waktu Mulai"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  @input="menu1 = false"
                  @change="changeFormat(startDate, '_start')"
                ></v-date-picker>
              </v-menu>
              <v-col cols="12" md="7" v-else>{{ taskById.start_date }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4"><label>Tenggat Waktu</label></v-col>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
                v-if="edttask == true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="taskById.end_date"
                    label="Waktu Deadline"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    solo
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="deadline"
                  @input="menu = false"
                  @change="changeFormat(deadline, '_end')"
                ></v-date-picker>
              </v-menu>
              <v-col cols="12" md="7" v-else>{{ taskById.end_date }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-row>
              <v-col cols="12" md="4"><label>Tahap</label></v-col>
              <v-col cols="12" md="7" v-if="edttask == true">
                <v-select
                  v-model="taskById.tahap"
                  :items="tahap"
                  item-text="state"
                  item-value="val"
                  label="Tahap"
                  item-color
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="7" v-else>{{ taskById.tahap }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4"><label>Prioritas</label></v-col>
              <v-col cols="12" md="7" v-if="edttask == true">
                <v-select
                  v-model="taskById.prioritas"
                  :items="priorities"
                  item-text="state"
                  item-value="val"
                  label="Prioritas"
                  item-color
                  solo
                ></v-select>
              </v-col>
              <v-col cols="12" md="7" v-else>{{ taskById.prioritas }} </v-col>
            </v-row>
          </v-col>
          <v-card-actions>
            <v-btn color="cyan" dark v-if="edttask == true" @click="updateTask"
              ><v-icon>mdi-pencil</v-icon>Simpan</v-btn
            >
            <v-btn
              color="red"
              v-if="edttask == true"
              dark
              @click="edttask = false"
              ><v-icon> mdi-delete </v-icon>Batal
            </v-btn>
          </v-card-actions>
          <DialogLoading v-bind:loading="loading" />
        </v-row>
        <h2 class="mb-1">Komentar</h2>
        <v-card elevation="1" class="my-3">
          <section
            class="pa-2"
            v-if="!$apollo.queries.taskCommentByTask.loading"
          >
            <div
              class="my-3"
              v-for="(comment, i) in taskCommentByTask"
              :key="i"
            >
              <v-divider></v-divider>
              <div class="mt-2">
                <section class="d-flex align-center justify-space-between">
                  <div class="d-flex align-center">
                    <v-avatar
                      size="40"
                      class="mr-5"
                      v-if="comment.user.data_avatar"
                    >
                      <img
                        :src="`${env}/avatar/${comment.user.data_avatar.file}`"
                        class="_fit"
                        alt=""
                      />
                    </v-avatar>
                    <h3>{{ comment.user.nama_lengkap }}</h3>
                    <small class="grey--text mx-2">{{
                      moment(comment.created_at).format("HH:mm")
                    }}</small>
                  </div>
                  <div v-if="comment.user.id == id">
                    <v-btn icon color="indigo" @click="edt = comment.id">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="red" @click="delCommentTask(comment.id)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </section>
                <section class="d-flex">
                  <div style="width: 60px"></div>
                  <span
                    v-html="comment.komentar"
                    v-if="edt !== comment.id"
                  ></span>
                  <div v-else>
                    <vue-editor
                      id="responsibilityEdit"
                      v-model="comment.komentar"
                      :editorToolbar="customToolbar"
                    ></vue-editor>
                    <v-btn color="red" dark class="ma-1" @click="edt = null"
                      >Batal</v-btn
                    >
                    <v-btn
                      color="indigo"
                      dark
                      class="ma-1"
                      @click="updateCommentTask(comment)"
                    >
                      simpan</v-btn
                    >
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section class="pa-2">
            <vue-editor
              id="responsibilityEdit"
              v-model="comment"
              :editorToolbar="customToolbar"
              class="my-2"
            ></vue-editor>
            <div class="d-flex justify-end">
              <div v-if="loadSend">
                <v-btn v-btn color="indigo" dark>
                  memuat
                  <v-progress-circular
                    indeterminate
                    size="20"
                    color="white"
                    class="ml-3"
                  ></v-progress-circular>
                </v-btn>
              </div>
              <div v-else>
                <v-btn color="indigo" dark @click="commentTask"
                  >tambah komentar</v-btn
                >
              </div>
            </div>
          </section>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_TASK_BYID,
  DELETE_TASK,
  COMMENT_TASK,
  COMMENT_BY_TASK,
  DELETE_COMMENT_TASK,
  UPDATE_COMMENT_TASK,
  UPDATE_TASK
} from "../../../graphql/Student";
import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";
import moment from "moment";
import DialogLoading from "../../../components/_base/dialogLoading";

export default {
  name: "taskDetail",
  apollo: {
    taskById: {
      variables() {
        return { id: this.idTask };
      },
      skip() {
        return !this.idTask;
      },
      query: GET_TASK_BYID,

      error(error) {
        console.log(error);
      }
    },
    taskCommentByTask: {
      variables() {
        return { task_id: this.idTask };
      },
      query: COMMENT_BY_TASK,
      // skip() {
      //   return !this.id_task;
      // },
      result(data) {
        console.log(data.data.taskCommentByTask);
      },
      error(error) {
        console.log(error);
      }
    }
  },
  props: ["dialogTask", "idTask", "id_group"],
  computed: {
    avatar() {
      let pp = null;
      if (this.taskById.user.data_avatar) {
        pp = `${process.env.VUE_APP_GRAPHQL}/avatar/${this.taskById.user.data_avatar.file}`;
      }
      return pp;
    },
    id_task() {
      let id_task = null;
      if (this.taskById) {
        id_task = parseInt(this.idTask);
      }
      return id_task;
    }
  },
  data() {
    return {
      id: null,
      taskById: null,
      comment: "",
      env: process.env.VUE_APP_GRAPHQL,
      taskCommentByTask: null,
      moment: moment,
      edt: false,
      edttask: false,
      menu: false,
      menu1: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
      // pilihan prioritas
      priorities: [
        { state: "Rendah", val: "RENDAH" },
        { state: "Sedang", val: "SEDANG" },
        { state: "Tinggi", val: "TINGGI" }
      ],
      tahap: [
        { state: "Belum dikerjakan", val: "NONE" },
        { state: "Sedang dikerjakan", val: "PROGRESS" },
        { state: "Selesai", val: "COMPLETED" }
      ],
      progress: "",
      priority: "",
      loadSend: false,
      loading: false,
      deadline: "",
      startDate: ""
    };
  },
  components: {
    VueEditor,
    DialogLoading
  },
  mounted() {
    this.id = localStorage.getItem("id");
  },
  methods: {
    delTask(id) {
      Swal.fire({
        title: "Yakin hapus tugas?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_TASK,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$emit("false");
              Swal.fire({
                icon: "success",
                title: "Task telah terhapus",
                timer: 3000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              this.loading = false;
              Swal.fire({
                icon: "error",
                title: err.graphQLErrors[0].message,
                text: "Anda tidak berhak menghapus task milik peserta lain",
                confirmButtonText: "ok",
                confirmButtonColor: "#3F51B5",
                position: "top",
                toast: true
              }).then(result => {
                if (result.isConfirmed) {
                  this.refetch();
                }
              });
            });
        }
      });
    },
    commentTask() {
      const task_id = parseInt(this.taskById.id);
      const user_id = parseInt(this.id);
      this.loadSend = true;
      this.$apollo
        .mutate({
          mutation: COMMENT_TASK,
          variables: {
            task_id: task_id,
            user_id: user_id,
            komentar: this.comment
          }
        })
        .then(data => {
          console.log(data);
          this.loadSend = false;
          this.comment = "";
          this.$apollo.queries.taskCommentByTask.refetch();
          setTimeout(function() {
            let scrollDown = document.querySelector(".comments");
            scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
          }, 500);
        });
    },
    delCommentTask(id) {
      Swal.fire({
        title: "Yakin hapus komentar?",
        // text: "Data akan dihapus dan tidak dapat dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$apollo
            .mutate({
              mutation: DELETE_COMMENT_TASK,
              variables: {
                id: id
              }
            })
            .then(() => {
              this.$apollo.queries.taskCommentByTask.refetch();
            });
          Swal.fire({
            icon: "success",
            title: "Komentar telah terhapus",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          }).then(() => {
            setTimeout(function() {
              let scrollDown = document.querySelector(".comments");
              scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
            }, 100);
          });
        }
      });
    },
    //update task
    updateCommentTask(comment) {
      console.log(comment);
      const id = parseInt(comment.id);
      const user_id = parseInt(comment.user_id);
      const task_id = parseInt(comment.task_id);
      const komentar = comment.komentar;
      this.$apollo
        .mutate({
          mutation: UPDATE_COMMENT_TASK,
          variables: {
            id: id,
            task_id: task_id,
            user_id: user_id,
            komentar: komentar
          }
        })
        .then(() => {
          this.$apollo.queries.taskCommentByTask.refetch();
          this.edt = null;
          Swal.fire({
            icon: "success",
            title: "Komentar telah terupdate",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: err.graphQLErrors[0].message,
            confirmButtonText: "ok",
            confirmButtonColor: "#3F51B5",
            position: "top",
            toast: true
          });
        });
    },
    //update task
    updateTask() {
      console.log(this.tahap);
      this.loading = true;
      const grup_id = parseInt(this.id_group);
      const user_id = parseInt(this.id);
      const id = parseInt(this.taskById.id);
      const judul = this.taskById.judul;
      const end_date = moment(this.taskById.end_date).format("YYYY-MM-DD");
      const start_date = moment(this.taskById.start_date).format("YYYY-MM-DD");
      this.$apollo
        .mutate({
          mutation: UPDATE_TASK,
          variables: {
            id: id,
            grup_id: grup_id,
            user_id: user_id,
            judul: judul,
            keterangan: this.taskById.keterangan,
            end_date: end_date,
            start_date: start_date,
            tahap: this.taskById.tahap,
            prioritas: this.taskById.prioritas
          }
        })
        .then(() => {
          this.$apollo.queries.taskById.refetch();
          this.loading = false;
          this.edttask = false;
          Swal.fire({
            icon: "success",
            title: "Task telah berhasil diubah",
            confirmButtonText: "ok",
            confirmButtonColor: "#3F51B5",
            position: "top",
            toast: true
          });
        })
        .catch(err => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err,
            confirmButtonText: "ok",
            confirmButtonColor: "#3F51B5",
            position: "top",
            toast: true
          }).then(result => {
            if (result.isConfirmed) {
              this.$apollo.queries.taskById.refetch();
            }
          });
        });
    },
    changeFormat(date, phase) {
      if (phase == "_start") {
        this.taskById.start_date = moment(date, "YYYY-MM-DD").format(
          "DD-MMMM-YYYY"
        );
      } else if (phase == "_end") {
        this.taskById.end_date = moment(date, "YYYY-MM-DD").format(
          "DD-MMMM-YYYY"
        );
      }
    }
  }
};
</script>

<style>
#responsibility .ql-editor {
  min-height: 50px;
}
._fit {
  object-fit: cover;
}
</style>
