<template>
  <div class="pa-3">
    <v-row>
      <v-progress-linear
        :active="loadTable"
        :indeterminate="loadTable"
        absolute
        top
        color="orange"
      ></v-progress-linear>
      <v-col cols="12" md="2" class="pb-0 mb-0">
        <label for="event">Pilih kelompok</label>
      </v-col>
      <v-col cols="12" md="10">
        <v-select
          :options="allGrup.data"
          :reduce="nama => nama.id"
          v-model="select_kelompok"
          @search="query => (find = query)"
          label="nama"
          class="white"
          @input="get_group(select_kelompok)"
        ></v-select>
      </v-col>
    </v-row>
    <div v-if="id_group">
      <TimetableStudent v-bind:id_group="id_group" />
    </div>
    <v-dialog v-model="progress" hide-overlay persistent width="250">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { NAME_GROUP } from "../../graphql/Group";
import TimetableStudent from "../../components/Student/timetableStudent";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "timetablementor",
  apollo: {
    allGrup: {
      query: NAME_GROUP,
      variables() {
        return { page: 1, first: 10, search: this.find };
      }
    }
  },
  components: {
    TimetableStudent,
    vSelect
  },
  computed: {
    loadTable() {
      return this.$apollo.queries.allGrup.loading;
    }
  },
  data() {
    return {
      progress: false,
      search: "",
      find: "",
      select_kelompok: null,
      src: false,
      id_group: null
    };
  },
  methods: {
    get_group(id) {
      this.src = false;
      this.id_group = id;
    },
    srcBlur() {
      document.activeElement.blur();
      this.src = false;
    }
  }
};
</script>

<style></style>
