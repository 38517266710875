<template>
  <div class="timetable">
    <v-progress-linear
      :active="loadTask"
      :indeterminate="loadTask"
      absolute
      top
      color="orange"
    ></v-progress-linear>
    <!-- CALENDAR COMPONENT -->
    <div>
      <v-row class="fill-height pa-2">
        <v-col cols="12" md="8">
          <v-sheet height="64">
            <v-toolbar color="grey lighten-5" flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="450">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
            ></v-calendar>
          </v-sheet>
        </v-col>
        <!-- ADD TASK -->
        <v-col cols="12" md="4" rounded v-if="role == 4">
          <v-card class="_transisi">
            <v-toolbar color="indigo" dense dark><h3>Task</h3></v-toolbar>
            <div class="pa-2 pt-5">
              <v-text-field
                label="Tambah Jadwal"
                v-model="judul"
                solo
                hide-details=""
              ></v-text-field>

              <transition name="fade">
                <div v-if="judul != ''">
                  <vue-editor
                    id="responsibilityEdit"
                    placeholder="Keterangan jadwal"
                    v-model="keterangan"
                    :editorToolbar="customToolbar"
                    class="my-5"
                    :error-messages="judulErr"
                    @input="$v.judul.$touch()"
                    @blur="$v.judul.$touch()"
                  ></vue-editor>

                   

                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="end_"
                        label="Waktu Deadline"
                        prepend-icon="mdi-calendar"
                        readonly
                        :disabled="!start_"
                        v-bind="attrs"
                        v-on="on"
                        solo
                        :error-messages="endDateErr"
                        @input="$v.end_.$touch()"
                        @blur="$v.end_.$touch()"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="minEnd"
                      v-model="deadline"
                      @input="menu = false"
                      @change="changeFormat(deadline, '_end')"
                    ></v-date-picker>
                  </v-menu>
                  <v-select
                    v-model="prioritas"
                    :items="priority"
                    item-text="state"
                    item-value="val"
                    label="Prioritas"
                    item-color
                    solo
                    :error-messages="prioritasErr"
                    @input="$v.prioritas.$touch()"
                    @blur="$v.prioritas.$touch()"
                  ></v-select>
                  <v-card-actions>
                    <v-btn color="indigo" @click="createTask" dark
                      >add task</v-btn
                    >
                    <v-btn @click="cancelTask" color="red" dark>Batal</v-btn>
                  </v-card-actions>
                </div>
              </transition>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="_max py-1">
        <!-- NO PROGRESS -->
        <v-col cols="12" md="4">
          <v-toolbar class="mb-2" color="cyan" dark rounded dense>
            Belum dikerjakan
          </v-toolbar>
          <draggable
            id="noProgress"
            class="list-group"
            v-model="taskByGrup"
            group="task"
            ghost-class="ghost"
            animation="150"
            @end="endMove"
          >
            <div
              class="list-group-item"
              v-for="task in taskByGrup"
              :key="task.id"
            >
              <transition name="fade">
                <v-alert
                  @click="taskDetail(task.id)"
                  border="left"
                  colored-border
                  color="cyan"
                  elevation="2"
                  class="_pointer pa-2"
                  v-if="task.tahap == 'NONE'"
                >
                  <div class="d-flex align-center">
                    <section style="width: 10%">
                      <v-menu offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            :color="
                              task.tahap == 'NONE'
                                ? 'cyan'
                                : task.tahap == 'PROGRESS'
                                ? 'warning'
                                : 'success'
                            "
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <v-icon> mdi-check-circle-outline </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, 'NONE', task.prioritas)"
                          >
                            <v-list-item-title>
                              <v-icon small color="cyan"
                                >mdi-checkbox-blank-circle-outline</v-icon
                              >
                              <small class="px-4">Belum dikerjakan</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="
                              updateTask(task, 'PROGRESS', task.prioritas)
                            "
                          >
                            <v-list-item-title>
                              <v-icon small color="warning"
                                >mdi-circle-slice-3</v-icon
                              >
                              <small class="px-4">Sedang dikerjakan</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="
                              updateTask(task, 'COMPLETED', task.prioritas)
                            "
                          >
                            <v-list-item-title>
                              <v-icon small color="success"
                                >mdi-checkbox-marked-circle</v-icon
                              >
                              <small class="px-4">Selesai</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </section>
                    <section class="px-3" style="width: 75%">
                      <div>
                        <h5>{{ task.judul }}</h5>
                      </div>
                      <div>
                        <small class="grey--text">
                          Tenggat waktu :
                          {{
                            moment(task.end_date, "YYYY-MM-DD").format(
                              "DD MMMM YYYY"
                            )
                          }}
                        </small>
                      </div>
                    </section>
                    <section style="width: 15%" class="d-flex justify-end">
                      <v-menu offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <!--  -->
                          <v-chip
                            class="pa-1"
                            :color="
                              task.prioritas == 'RENDAH'
                                ? 'cyan'
                                : task.prioritas == 'SEDANG'
                                ? 'warning'
                                : 'red'
                            "
                            label
                            text-color="white"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <small>{{ task.prioritas }}</small>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'RENDAH')"
                          >
                            <v-list-item-title>
                              <v-icon small color="cyan"
                                >mdi-chevron-right</v-icon
                              >
                              <small class="px-4">Rendah</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'SEDANG')"
                          >
                            <v-list-item-title>
                              <v-icon small color="warning"
                                >mdi-chevron-double-right</v-icon
                              >
                              <small class="px-4">Sedang</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'TINGGI')"
                          >
                            <v-list-item-title>
                              <v-icon small color="red"
                                >mdi-chevron-triple-right</v-icon
                              >
                              <small class="px-4">Tinggi</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </section>
                  </div>
                </v-alert>
              </transition>
            </div>
          </draggable>
        </v-col>
        <!-- IN PROGRESS -->
        <v-col cols="12" md="4">
          <v-toolbar class="mb-2" color="warning" dark rounded dense>
            Sedang dikerjakan
          </v-toolbar>
          <draggable
            class="list-group"
            id="inProgress"
            v-model="taskByGrup"
            group="task"
            ghost-class="ghost"
            animation="150"
            @end="endMove"
          >
            <div
              class="list-group-item"
              v-for="task in taskByGrup"
              :key="task.id"
            >
              <transition name="fade">
                <v-alert
                  @click="taskDetail(task.id)"
                  border="left"
                  colored-border
                  color="warning"
                  elevation="2"
                  class="_pointer pa-2"
                  v-if="task.tahap == 'PROGRESS'"
                >
                  <div class="d-flex align-center">
                    <section style="width: 10%">
                      <v-menu offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            :color="
                              task.tahap == 'NONE'
                                ? 'cyan'
                                : task.tahap == 'PROGRESS'
                                ? 'warning'
                                : 'succsess'
                            "
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <v-icon> mdi-check-circle-outline </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, 'NONE', task.prioritas)"
                          >
                            <v-list-item-title>
                              <v-icon small color="cyan"
                                >mdi-checkbox-blank-circle-outline</v-icon
                              >
                              <small class="px-4">Belum dikerjakan</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="
                              updateTask(task, 'PROGRESS', task.prioritas)
                            "
                          >
                            <v-list-item-title>
                              <v-icon small color="warning"
                                >mdi-circle-slice-3</v-icon
                              >
                              <small class="px-4">Sedang dikerjakan</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="
                              updateTask(task, 'COMPLETED', task.prioritas)
                            "
                          >
                            <v-list-item-title>
                              <v-icon small color="success"
                                >mdi-checkbox-marked-circle</v-icon
                              >
                              <small class="px-4">Selesai</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </section>
                    <section class="px-3" style="width: 75%">
                      <div>
                        <h5>{{ task.judul }}</h5>
                      </div>
                      <div>
                        <small class="grey--text">
                          Tenggat waktu :
                          {{
                            moment(task.end_date, "YYYY-MM-DD").format(
                              "DD MMMM YYYY"
                            )
                          }}
                        </small>
                      </div>
                    </section>
                    <section style="width: 15%" class="d-flex justify-end">
                      <v-menu offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <!--  -->
                          <v-chip
                            class="pa-1"
                            :color="
                              task.prioritas == 'RENDAH'
                                ? 'cyan'
                                : task.prioritas == 'SEDANG'
                                ? 'warning'
                                : 'red'
                            "
                            label
                            text-color="white"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <small>{{ task.prioritas }}</small>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'RENDAH')"
                          >
                            <v-list-item-title>
                              <v-icon small color="cyan"
                                >mdi-chevron-right</v-icon
                              >
                              <small class="px-4">Rendah</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'SEDANG')"
                          >
                            <v-list-item-title>
                              <v-icon small color="warning"
                                >mdi-chevron-double-right</v-icon
                              >
                              <small class="px-4">Sedang</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'TINGGI')"
                          >
                            <v-list-item-title>
                              <v-icon small color="red"
                                >mdi-chevron-triple-right</v-icon
                              >
                              <small class="px-4">Tinggi</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </section>
                  </div>
                </v-alert>
              </transition>
            </div>
          </draggable>
        </v-col>
        <!-- COMPLETED -->
        <v-col cols="12" md="4">
          <v-toolbar class="mb-2" color="success" dark rounded dense>
            Selesai
          </v-toolbar>
          <draggable
            id="completed"
            class="list-group"
            v-model="taskByGrup"
            group="task"
            ghost-class="ghost"
            animation="150"
            @end="endMove"
          >
            <div v-for="task in taskByGrup" :key="task.id">
              <transition name="fade">
                <v-alert
                  @click="taskDetail(task.id)"
                  border="left"
                  colored-border
                  color="success"
                  elevation="2"
                  class="_pointer pa-2"
                  v-if="task.tahap == 'COMPLETED'"
                >
                  <div class="d-flex align-center">
                    <section style="width: 10%">
                      <v-menu offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <v-btn
                            :color="
                              task.tahap == 'NONE'
                                ? 'cyan'
                                : task.tahap == 'PROGRESS'
                                ? 'warning'
                                : 'success'
                            "
                            v-bind="attrs"
                            v-on="on"
                            icon
                          >
                            <v-icon> mdi-check-circle-outline </v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, 'NONE', task.prioritas)"
                          >
                            <v-list-item-title>
                              <v-icon small color="cyan"
                                >mdi-checkbox-blank-circle-outline</v-icon
                              >
                              <small class="px-4">Belum dikerjakan</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="
                              updateTask(task, 'PROGRESS', task.prioritas)
                            "
                          >
                            <v-list-item-title>
                              <v-icon small color="warning"
                                >mdi-circle-slice-3</v-icon
                              >
                              <small class="px-4">Sedang dikerjakan</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="
                              updateTask(task, 'COMPLETED', task.prioritas)
                            "
                          >
                            <v-list-item-title>
                              <v-icon small color="success"
                                >mdi-checkbox-marked-circle</v-icon
                              >
                              <small class="px-4">Selesai</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </section>
                    <section class="px-3" style="width: 75%">
                      <div>
                        <h5>{{ task.judul }}</h5>
                      </div>
                      <div>
                        <small class="grey--text">
                          Tenggat waktu :
                          {{
                            moment(task.end_date, "YYYY-MM-DD").format(
                              "DD MMMM YYYY"
                            )
                          }}
                        </small>
                      </div>
                    </section>
                    <section style="width: 15%" class="d-flex justify-end">
                      <v-menu offset-y>
                        <template v-slot:activator="{ attrs, on }">
                          <!--  -->
                          <v-chip
                            class="pa-1"
                            :color="
                              task.prioritas == 'RENDAH'
                                ? 'cyan'
                                : task.prioritas == 'SEDANG'
                                ? 'warning'
                                : 'red'
                            "
                            label
                            text-color="white"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <small>{{ task.prioritas }}</small>
                          </v-chip>
                        </template>
                        <v-list>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'RENDAH')"
                          >
                            <v-list-item-title>
                              <v-icon small color="cyan"
                                >mdi-chevron-right</v-icon
                              >
                              <small class="px-4">Rendah</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'SEDANG')"
                          >
                            <v-list-item-title>
                              <v-icon small color="warning"
                                >mdi-chevron-double-right</v-icon
                              >
                              <small class="px-4">Sedang</small>
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            link
                            class="py-0"
                            @click="updateTask(task, task.tahap, 'TINGGI')"
                          >
                            <v-list-item-title>
                              <v-icon small color="red"
                                >mdi-chevron-triple-right</v-icon
                              >
                              <small class="px-4">Tinggi</small>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </section>
                  </div>
                </v-alert>
              </transition>
            </div>
          </draggable>
        </v-col>
      </v-row>
      <div v-if="idTask">
        <TaskDetail
          v-bind:dialogTask="dialogTask"
          v-bind:idTask="idTask"
          v-bind:id_group="id_group"
          v-on:false="dialogFalse()"
        />
      </div>
      <DialogLoading v-bind:loading="loading" />
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import moment from "moment";
import { mapState } from "vuex";
import {
  CREATE_TASK,
  GET_TASK_BYID,
  GET_TASK_GROUP,
  UPDATE_TASK,
  CREATE_BADGE_PESERTA,
  GET_TASK_BY_USER_ID,
} from "../../graphql/Student";
import { JUST_REFETCH_PROFILE } from "../../graphql/graphql";
import DialogLoading from "../../components/_base/dialogLoading";
import TaskDetail from "../../components/Student/Modal/TaskDetail";
import draggable from "vuedraggable";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  apollo: {
    profile: {
      query: JUST_REFETCH_PROFILE,
    },
    taskByUser: {
      variables() {
        return { user_id: localStorage.getItem("id") };
      },
      skip() {
        return !localStorage.getItem("id");
      },
      query: GET_TASK_BY_USER_ID,
      // result(data) {
      //   console.log(data.data.taskByUser.length)
      // },
      error(error) {
        console.log(error);
      },
    },
    taskByGrup: {
      variables() {
        return { grup_id: this.id_group };
      },
      skip() {
        return !this.id_group;
      },
      query: GET_TASK_GROUP,
      error(error) {
        console.log(error);
      },
    },
    taskById: {
      variables() {
        return { id: this.idTask };
      },
      skip() {
        return !this.idTask;
      },
      query: GET_TASK_BYID,
      error(error) {
        console.log(error);
      },
    },
  },
  name: "timeTableStudent",
  props: ["id_group"],
  mixins: [validationMixin],
  validations: {
    judul: { required },
    start_: { required },
    end_: { required },
    prioritas: { required },
  },
  data() {
    return {
      tempArr: [],
      page: 1,
      limit: 10,
      pilihGrup: false,
      role: localStorage.getItem("role_id"),
      headers: [
        { text: "id", value: "id" },
        { text: "Nama Kelompok", value: "nama" },
        {
          text: "Nama Pelatihan",
          align: "start",
          sortable: false,
          value: "pelatihan.nama",
        },
        {
          text: "Tipe",
          align: "start",
          sortable: false,
          value: "tipe",
        },
        { text: "Aksi", value: "actions" },
      ],
      //set up calendar
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      taskById: [],
      taskByUser: [],
      //toolbar for text eiditor
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }],
      ],
      // pilihan prioritas
      priority: [
        { state: "Rendah", val: "RENDAH" },
        { state: "Sedang", val: "SEDANG" },
        { state: "Tinggi", val: "TINGGI" },
      ],
      // v-model create task
      prioritas: "",
      judul: "",
      proggress: "",
      keterangan: "",
      end_: "",
      start_: "",
      deadline: "",
      startDate: "",
      menu: false,
      menu1: false,
      taskByGrup: null,
      //moment
      moment: moment,
      //etc
      dialogTask: false,
      idTask: null,
      loading: false,
    };
  },
  components: {
    VueEditor,
    TaskDetail,
    draggable,
    DialogLoading,
  },
  computed: {
    ...mapState(["userProfile", "badge_task", "existing_badge"]),
    minEnd() {
      return moment(this.startDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
    },
    loadTask() {
      //loading task
      return this.$apollo.queries.taskByGrup.loading;
    },
    events() {
      let arr = [];
      if (this.taskByGrup) {
        for (let i = 0; i < this.taskByGrup.length; i++) {
          arr.push({
            name: this.taskByGrup[i].judul,
            start: this.taskByGrup[i].start_date,
            end: this.taskByGrup[i].end_date,
            color:
              this.taskByGrup[i].tahap == "NONE"
                ? "cyan"
                : this.taskByGrup[i].tahap == "PROGRESS"
                ? "warning"
                : "success",
            category:
              this.taskByGrup[i].tahap == "NONE"
                ? "Belum dikerjakan"
                : this.taskByGrup[i].tahap == "PROGRESS"
                ? "Sedang dikerjakan"
                : "Selesai",
            id: this.taskByGrup[i].id,
          });
        }
      }
      return arr;
    },
    judulErr() {
      const errors = [];
      if (!this.$v.judul.$dirty) return errors;
      !this.$v.judul.required &&
        errors.push("Judul task tidak boleh kosong!");
      return errors;
    },
    startDateErr() {
      const errors = [];
      if (!this.$v.start_.$dirty) return errors;
      !this.$v.start_.required && errors.push("Waktu mulai tidak boleh kosong!");
      return errors;
    },
    endDateErr() {
      const errors = [];
      if (!this.$v.end_.$dirty) return errors;
      !this.$v.end_.required && errors.push("Waktu deadline tidak boleh kosong!");
      return errors;
    },
    prioritasErr() {
      const errors = [];
      if (!this.$v.prioritas.$dirty) return errors;
      !this.$v.prioritas.required &&
        errors.push("Prioritas tidak boleh kosong!");
      return errors;
    },
  },
  watch: {
    taskByUser(val) {
      console.log(val.length);
      if (val.length == 5) {
        this.showBadge();
      }
    },
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    endMove(evt) {
      let progress = evt.to.id;
      let task = evt.item._underlying_vm_;
      switch (progress) {
        case "noProgress":
          this.updateTask(task, "NONE", null);
          break;
        case "inProgress":
          this.updateTask(task, "PROGRESS", null);
          break;
        case "completed":
          this.updateTask(task, "COMPLETED", null);
          break;
      }
      // console.log(evt.clone)
    },
    refetch() {
      this.$apollo.queries.taskByGrup.refetch();
      this.$apollo.queries.taskByUser.refetch();
    },
    createTask() {
      this.$v.judul.$touch();
      this.$v.start_.$touch();
      this.$v.end_.$touch();
      this.$v.prioritas.$touch();
      if (!this.$v.judul.required || !this.$v.prioritas.required) {
        console.log("datanya kosong cuy");
        Swal.fire({
              icon: "error",
              title: "Data masih ada yang kosong",
              confirmButtonText: "ok",
              confirmButtonColor: "#3F51B5",
              position: "top",
              toast: true,
            });
        this.loading = false;
        return;
      } 
      else if (!this.$v.start_.required || !this.$v.end_.required){
        console.log("datanya kosong cuy");
        Swal.fire({
              icon: "error",
              title: "Data masih ada yang kosong",
              confirmButtonText: "ok",
              confirmButtonColor: "#3F51B5",
              position: "top",
              toast: true,
            });
        this.loading = false;
        return;
      }
      else {
        const grup_id = parseInt(this.id_group);
        const user_id = parseInt(this.userProfile.id);
        const start_date = moment(this.startDate).format("YYYY-MM-DD");
        const end_date = moment(this.deadline).format("YYYY-MM-DD");
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: CREATE_TASK,
            variables: {
              grup_id: grup_id,
              user_id: user_id,
              judul: this.judul,
              tahap: "NONE",
              keterangan: this.keterangan,
              start_date: start_date,
              end_date: end_date,
              prioritas: this.prioritas,
              created_by: user_id,
            },
          })
          .then((data) => {
            console.log(data.data);
            this.refetch();
            this.judul = "";
            this.keterangan = "";
            this.prioritas = null;
            this.deadline = null;
            this.start_ = null;
            this.end_ = null;
            let scrollDown = document.querySelector(".timetable");
            scrollDown.scrollTop = scrollDown.scrollHeight; //auto scroll bottom of page
            this.loading = false;
            this.$v.$reset()
          })
          .catch((err) => {
            console.log(err);
            this.loading = false;
            Swal.fire({
              icon: "error",
              title: err,
              confirmButtonText: "ok",
              confirmButtonColor: "#3F51B5",
              position: "top",
              toast: true,
            });
          });
      }
    },
    async showBadge() {
      this.$apollo
        .mutate({
          mutation: CREATE_BADGE_PESERTA,
          variables: {
            user_id: localStorage.getItem("id"),
            badge_id: 5,
          },
        })
        .then((data) => {
          if (data.data.createBadgePeserta.id) {
            this.$store
              .dispatch("returnBadge", { tipe: "task" })
              .then((data) => {
                console.log(data.data.badge);
                Swal.fire({
                  imageUrl: `${process.env.VUE_APP_GRAPHQL}/badges/${data.data.badge}`,
                  imageHeight: 150,
                  title: data.data.pesan,
                  showConfirmButton: true,
                  confirmButtonColor: "#3F51B5",
                  confirmButtonText: "Ok",
                });
              });
          }
        });
    },
    //update task
    updateTask(task, progress, priority) {
      console.log(task);
      this.loading = true;
      const grup_id = parseInt(this.id_group);
      const user_id = task.created_by;
      const id = parseInt(task.id);
      const judul = task.judul;
      const keterangan = task.keterangan;
      const tahap = progress ? progress : task.tahap;
      const prioritas = priority ? priority : task.prioritas;
      const end_date = task.end_date;
      const start_date = task.start_date;
      this.$apollo
        .mutate({
          mutation: UPDATE_TASK,
          variables: {
            id: id,
            grup_id: grup_id,
            user_id: user_id,
            judul: judul,
            keterangan: keterangan,
            end_date: end_date,
            start_date: start_date,
            tahap: tahap,
            prioritas: prioritas,
          },
        })
        .then(() => {
          this.refetch();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          
          Swal.fire({
            icon: "error",
            title: err.graphQLErrors[0].message,
            text: "Maaf Anda tidak bisa merubah task",
            confirmButtonText: "ok",
            confirmButtonColor: "#3F51B5",
            position: "top",
            toast: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.refetch();
            }
          });
        });
    },

    //mengganti format tanggal
    changeFormat(date, phase) {
      if (phase == "_start") {
        this.start_ = moment(date, "YYYY-MM-DD").format("DD-MMMM-YYYY");
      } else if (phase == "_end") {
        this.end_ = moment(date, "YYYY-MM-DD").format("DD-MMMM-YYYY");
      }
    },
    taskDetail(id) {
      this.idTask = parseInt(id);
      this.dialogTask = true;
    },
    dialogFalse() {
      this.dialogTask = false;
      this.refetch();
    },
    cancelTask() {
      this.judul = "";
      this.keterangan = "";
      this.prioritas = null;
      this.deadline = null;
    },

    // set up caelndar
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        // this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        this.idTask = parseInt(event.id);
        this.taskDetail(event.id);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    viewEvent(item) {
      this.$router.push(`timetable/${item.id}`);
    },
  },
};
</script>

<style>
.timetable {
  scroll-behavior: smooth;
}
.bg-default {
  background: #f4f6f9;
}
.post-temp {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  overflow-x: scroll;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#responsibilityEdit .ql-editor {
  min-height: 100px;
}
._transisi {
  transition: 5s !important;
}
._pointer {
  cursor: pointer;
}
.list-group {
  padding: 10px 0;
  min-height: 300px;
}
/* ._max {
  min-height: 300px;

} */
</style>
